<template>
<div class="content-panel">
  <el-row :gutter="10" type="flex" justify="space-between" align="middle" class="breadcrumb-wrap">
    <el-col :span="20">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/' }">{{msg('首页')}}</el-breadcrumb-item>
        <el-breadcrumb-item>{{msg('账户管理')}}</el-breadcrumb-item>
      </el-breadcrumb>
    </el-col>
    <el-col :span="4" style="text-align:right;">
      <el-button type="success" size="small" icon="el-icon-refresh" @click="refresh()" circle></el-button>
    </el-col>
  </el-row>

  <el-row :gutter="10"  class="query-wrap" :style="{height: isQueryExpend?'auto':'55px'}">
    <el-col :xs="24" :sm="8" :lg="6" :xl="4">
        <el-input  :placeholder="msg('账号')" v-model="query.username" size="small">
          <i slot="prefix" class="el-input__icon el-icon-user"></i>
        </el-input>
    </el-col>
    <el-col :xs="24" :sm="8" :lg="6" :xl="4">
        <el-input  :placeholder="msg('姓名')" v-model="query.fullname" size="small">
          <i slot="prefix" class="el-input__icon el-icon-user"></i>
        </el-input>
    </el-col>
    <el-col :xs="24" :sm="8" :lg="6" :xl="4">
       <el-date-picker  v-model="query.Date"  type="daterange"  :range-separator="msg('至')" 
            :start-placeholder="msg('创建日期')"  value-format="yyyy-MM-dd"
            end-placeholder=""
            size="small"
          >
        </el-date-picker>
    </el-col>
    <el-col :xs="24" :sm="8" :lg="6" :xl="4">
      <el-select v-model="query.role" class="query-select" placeholder="选择角色" size="small" clearable>
         <el-option
          v-for="item in roles"
          :key="item.id"
          :label="item.label"
          :value="item.id">
        </el-option>
      </el-select>
    </el-col>
  </el-row>

  <el-row :gutter="10" type="flex" justify="space-between" align="center" class="ctrl-wrap">
    <el-col :span="12">
      <el-button-group>
        <el-button type="primary" size="mini" @click="add()">
          <i class="el-icon-circle-plus-outline" style="display:inline-block;margin-right:4px;"></i>
          <transition name="el-fade-in-linear">
            <span v-show="!isMinScreen">{{msg('添加')}}</span>
          </transition>
        </el-button>
        <el-button type="primary" size="mini" @click="edit()">
          <i class="el-icon-edit" style="display:inline-block;margin-right:4px;"></i>
          <transition name="el-fade-in-linear">
            <span v-show="!isMinScreen">{{msg('编辑')}}</span>
          </transition>
        </el-button><el-button type="warning" size="mini" @click="runAs()" v-if="hasPermission('account:runAs')">
          <i class="el-icon-magic-stick" style="display:inline-block;margin-right:4px;"></i>
          <transition name="el-fade-in-linear">
            <span v-show="!isMinScreen">{{msg('登录此用户')}}</span>
          </transition>
        </el-button>
        <el-button type="danger" size="mini" @click="deleted()">
          <i class="el-icon-delete" style="display:inline-block;margin-right:4px;"></i>
          <transition name="el-fade-in-linear">
            <span v-show="!isMinScreen">{{msg('删除')}}</span>
          </transition>
        </el-button>
      </el-button-group>
    </el-col>
    <el-col :span="12" style="text-align:right;">
      <el-button-group>
        <el-button type="success" @click="getAccounts(true)" size="mini">
          <i class="el-icon-search" style="display:inline-block;margin-right:4px; "></i>
          <transition name="el-fade-in-linear">
            <span v-show="!isMinScreen">{{msg('搜索')}}</span>
          </transition>
        </el-button>
        <el-button type="info" size="mini" @click="expendQuery()">
          <i class="el-icon-arrow-down" v-if="!isQueryExpend" style="display:inline-block;margin-right:4px; "></i>
          <i class="el-icon-arrow-up"  v-else style="display:inline-block;margin-right:4px; "></i>
          <transition name="el-fade-in-linear">
            <span v-show="!isMinScreen">{{msg('高级')}}</span>
          </transition>
        </el-button>
        <column-filter @seleted="(cols)=>this.columns = cols" :table-refs="this.$refs.dataTable" column-name="account-filter"/>
      </el-button-group>
    </el-col>
  </el-row> 
  
  <div class="table-wrap">
    <el-table :data="accounts" style="width: 100%" :height="docHeight" size="mini" ref="dataTable" stripe>
      <el-table-column type="selection"  min-width="55" :fixed="!isMinScreen"></el-table-column>
      <el-table-column v-if="columns['defaultShow'] || columns.id" prop="id" :label="msg('ID')" min-width="70" :fixed="!isMinScreen"></el-table-column>
      <el-table-column v-if="columns['defaultShow'] || columns.username" prop="username" :label="msg('账号')" min-width="80" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column v-if="columns['defaultShow'] || columns.fullname" prop="fullname" :label="msg('姓名')" min-width="80"></el-table-column>
      <el-table-column v-if="columns['defaultShow'] || columns.roleName" prop="roleName" :label="msg('角色')" min-width="80"></el-table-column>
      <el-table-column v-if="columns['defaultShow'] || columns.disabled" prop="disabled" :label="msg('状态')" min-width="80">
        <template slot-scope="scope">
          <el-tag :type="scope.row.disabled === 1 ? 'warning' : 'success'" disable-transitions>{{scope.row.disabled == 1 ? "禁用":"正常"}}</el-tag>
        </template>
      </el-table-column>
      <el-table-column v-if="columns['defaultShow'] || columns.created" prop="created" :label="msg('注册日期')" min-width="120"></el-table-column>
      <el-table-column v-if="columns['defaultShow'] || columns.vipExipre" prop="vipExipre" :label="msg('会员到期时间')" min-width="120"></el-table-column>
      <el-table-column v-if="columns['defaultShow'] || columns.telephone" prop="telephone" :label="msg('联系方式')" min-width="120"></el-table-column>
      <el-table-column v-if="columns['defaultShow'] || columns.userTag" prop="userTag" :label="msg('用户标识')" min-width="120"></el-table-column>
      <el-table-column prop="ticketTag" :label="msg('货单标识')" min-width="120"></el-table-column>
      <el-table-column v-if="columns['defaultShow'] || columns.creatName" prop="creatName" :label="msg('创建人')" min-width="80"></el-table-column>
      <el-table-column v-if="columns['defaultShow'] || columns.printed" prop="printed" :label="msg('打印品名')" min-width="80">
        <template slot-scope="scope">
          <el-tag :type="scope.row.printed === 1 ? 'success' : 'warning'" disable-transitions>{{scope.row.printed == 1 ? "打印":"不打印"}}</el-tag>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination id="xpagination"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :background="true"
      :current-page.sync="currentPage"
      :page-sizes="[10, 20,50, 100, 200, 300, 400]"
      :page-size="query.length"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total">
    </el-pagination>
  </div>
 
  <el-dialog :title="dglogTitle" :visible.sync="dialogTableVisible" :fullscreen="isMinScreen" width="650px">
    <account-edit :id="currId" :account="currAccount" v-on:closeDg="closeEditDialog" ref="accountEdit"/>
  </el-dialog>
</div>
</template> 

<script>
// @ is an alias to /src
import AccountEdit from "./AccountEdit.vue";
import TableColumnFilter from "../../components/TableColumnFilter.vue";

export default {
  name: 'account',
  components: {
    AccountEdit: AccountEdit,
    ColumnFilter: TableColumnFilter
  },
  mounted:function(){
    this.getAccounts();
    this.getRole();
    var _this = this;
    this.columns = this.$kit.getColumns('account-filter');
  },
  data(){
    return{
        query:{  //查询条件
          start: 0,
          length: 10,
          username: "",
          fullname: "",
          Date: [], 
          role:null,
        },
        currentPage: 1,   //分页的当前页
        accounts: [],   //表格数据列表
        dglogTitle: this.msg('编辑账号'),
        isQueryExpend: this.$store.state.isQueryExpend,  //查询框是否展开
        total: 0,//总记录数,
        currId: null,   //当前列表中选中的id
        dialogTableVisible: false, //编辑详情的隐藏与显示,
        columns: [],  //字段是否显示
        currAccount:null,
        roles:[],
    }
  }, 
  computed: {
    isMinScreen: {  //是否小屏幕，浏览器
        get() {
          return this.$store.state.isMinScreen;
        }
    },
    docHeight: {  //表格的高度
        get() {
          var height =  this.$store.state.docHeight;
          return height - 320;
        }
    }
  },
  methods: {
    getAccounts: function(start){ //获取列表
      var _this = this;

      var param = this.$kit.rebuildQuery(this.query);
      if(start){
        param.start = 0;
        this.currentPage = 1;
      }

      this.axios.post(this.$kit.api.accountList, param).then(function (result) {
        if(result.status){
          if(_this.accounts.length>0) 
            _this.accounts.splice(0, _this.accounts.length);
          
          _this.total = result.data.recordsTotal;
          var accountDatas = result.data.data;

          for(var i=0; i<accountDatas.length; i++){
            var item = accountDatas[i];
            if(item.created){
              item.created = item.created.substring(0,10);
            }

            // _this.accounts.push(item);
          } 
           _this.accounts = accountDatas;

        }else{
          _this.$message(result.msg);
        }
        
      }).catch(function (error) {
        console.error(error);
      });

    },//end function
    //获取角色列表
    getRole(){
        var _this = this;
        var param = {
            start: 0,
            length: 20,
        }
      this.axios.post(this.$kit.api.roleList,param).then(function (result) {
        if(result.status){
          if(_this.roles.length>0)
            _this.roles.splice(0, _this.roles.length);
          
          // _this.total = result.data.recordsTotal;
          var roleDatas = result.data.data;

          for(var i=0; i<roleDatas.length; i++){
            var item = roleDatas[i];
            if(item.created){
              item.created = item.created.substring(0,10);
            }

            _this.roles.push(item);
          } 

        }else{
          _this.$message(result.msg);
        }
        
      }).catch(function (error) {
        console.error(error);
      });
    },
    refresh: function(){  //刷新列表
      this.$kit.clearQuery(this.query);
      this.currentPage = 1;
      this.getAccounts();
    },
    expendQuery: function(){  //展开与收缩查询框
        this.isQueryExpend = !this.isQueryExpend;
    },
    handleSizeChange(val) {  //分页的每页数据变化后的回调事件
      //console.log(`每页 ${val} 条`);
      this.query.length = val;
      this.query.start = 0;
      this.getAccounts();
    },
    handleCurrentChange(val) {   //分页的当前页改变后的回调事件
      //console.log(`当前页: ${val}`);
      this.query.start = this.query.length * (val -1);
      this.getAccounts();
    },
    add(){  //添加
      this.currId = null;
      this.currAccount = {
        username: null,
        fullname: null,
        gender: null,
        disabled: null,
        roleId:null,
        password: null, 
        repassword: null,
        vipExipre: null,
        telephone:null,
        storeCode:null,
        userTag: null,
        printed:0,
        ticketTag:null,
      };
      this.dglogTitle = this.msg("添加新账号");
      
      // var accountEdit = this.$refs.accountEdit;
      // if(accountEdit){
      //   accountEdit.initForm(null);
      // }

      this.dialogTableVisible = true;
    },
    edit(){  //修改
      var _this = this;
      var selectes = this.$refs.dataTable.selection;
      if(selectes.length==0){
        this.$message(this.msg('请选中一条数据进行操作'));
        return;
      }
      _this.currAccount = selectes[0];
      delete _this.currAccount.created;
      // if(_this.currAccount.vipExipre && _this.currAccount.vipExipre.length>8){
      //   _this.currAccount.vipExipre ;
      // }
      console.log(_this.currAccount);
      _this.currAccount.password = null;
      _this.currAccount['repassword'] = null;
      _this.currAccount.vipExipre = null;
      _this.currId = _this.currAccount.id;
      _this.dglogTitle = this.msg("编辑账号");
      _this.dialogTableVisible = true;

      // var selectes = this.$refs.dataTable.selection;
      // if(selectes.length==0){
      //   this.$message("请选中一条数据进行操作");
      //   return;
      // }
      // var account = selectes[0];
      // this.currId = account.id;
      // this.dglogTitle = "编辑账号："+account.fullname;
      // var accountEdit = this.$refs.accountEdit;
      // if(accountEdit){
      //   accountEdit.initForm(account.id);
      // }
      
      // this.dialogTableVisible = true;
    },
    closeEditDialog(type){  //编辑弹窗关闭后的回调事件
      if(type){
        this.getAccounts();
      }
      this.dialogTableVisible = false;
    },
    deleted(){  //删除
      var selectes = this.$refs.dataTable.selection;
      if(selectes.length==0){
        this.$message(this.msg('请选中一条数据进行操作'));
        return;
      }

      var account = selectes[0];
      
      var _this = this;

      this.$confirm(this.msg('确定要删除该账户吗?'), this.msg('提示'), {
          confirmButtonText: this.msg('确定'),
          cancelButtonText: this.msg('取消'),
          type: 'warning'
        }).then(() => {
          _this.$axios.post(_this.$kit.api.accountUpdate, {id: account.id, deleted: 1})
            .then(function(result){
              _this.$message(result.msg || _this.msg('删除成功'));
              _this.getAccounts();
          });
        });
    },
    runAs(){
      var selectes = this.$refs.dataTable.selection;
      if(selectes.length==0){
        this.$message(this.msg('请选中一条数据进行操作'));
        return;
      }

      var account = selectes[0];
      this.$axios.get(this.$kit.api.accountRunAs+"?id="+account.id)
          .then((result)=>{
            if(result.status){
              console.log(result.data);
              localStorage.setItem("user", JSON.stringify(result.data));
              window.location.reload(true);
            }else{
               this.$message(result.msg);
            }
        });
    }
    
  }
}
</script>

<style class="scss" scoped>


</style>
