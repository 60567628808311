<template>
<el-form ref="form" :model="account" label-width="80px">
    <el-row>
        <el-col :xs="24" :sm="12">
            <el-form-item :label="msg('账号')">
                <el-input v-model="account.username"></el-input>
            </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12">
            <el-form-item :label="msg('姓名')">
                <el-input v-model="account.fullname"></el-input>
            </el-form-item>
        </el-col>
    </el-row>
    <el-row>
        <el-col :xs="24" :sm="12">
            <el-form-item :label="msg('密码')">
                <el-input v-model="account.password" type="password"></el-input>
            </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12">
            <el-form-item :label="msg('确认密码')">
                <el-input v-model="account.repassword" type="password"></el-input>
            </el-form-item>
        </el-col>
    </el-row>
    <el-row>
        <el-col :xs="24" :sm="12">
            <el-form-item :label="msg('性别')">
                <el-radio-group v-model="account.gender">
                    <el-radio :label="msg('男')"></el-radio>
                    <el-radio :label="msg('女')"></el-radio>
                    <el-radio :label="msg('保密')"></el-radio>
                </el-radio-group>
            </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12">
            <el-form-item :label="msg('角色')">
                <el-select v-model="account.roleId" :placeholder="msg('请选择')">
                    <el-option v-for="item in roles" :key="item.id" :label="item.label" :value="item.id"></el-option>
                </el-select>
            </el-form-item>
        </el-col>
    </el-row>

    <el-row>
        <el-col :xs="24" :sm="12">
            <el-form-item :label="msg('会员到期时间')">
                <el-date-picker value-format="yyyy-MM-dd HH:mm:ss"
                    v-model="account.vipExipre"
                    type="datetime"
                    :placeholder="msg('会员到期时间')">
                </el-date-picker>
            </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12">
            <el-form-item :label="msg('状态')">
                <el-switch style="display: block; margin-top: 9px;" v-model="account.disabled"
                    active-color="#ff4949"  inactive-color="#13ce66"
                    active-text="禁用" inactive-text="正常"
                    :active-value="1" :inactive-value="0">
                </el-switch>
            </el-form-item>
        </el-col>
    </el-row>
    <el-row>
        <el-col :xs="24" :sm="12">
            <el-form-item :label="msg('联系方式')">
                <el-input v-model="account.telephone"></el-input>
            </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12">
            <el-form-item :label="msg('仓位号')">
                <el-input v-model="account.storeCode"></el-input>
            </el-form-item>
        </el-col>
    </el-row>
    <el-row>
        <el-col :xs="24" :sm="12">
            <el-form-item :label="msg('用户标识')">
                <el-input v-model="account.userTag"></el-input>
            </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12">
            <el-form-item :label="msg('货单品名')">
                <el-radio v-model="account.printed" :label="1">打印</el-radio>
                <el-radio v-model="account.printed" :label="0">不打印</el-radio>
            </el-form-item>
        </el-col>
    </el-row>
    <el-row>
        <el-col :xs="24" :sm="12">
            <el-form-item :label="msg('货单标识')">
                <el-input v-model="account.ticketTag"></el-input>
            </el-form-item>
        </el-col>
    </el-row>

    <div style="text-align:center;">
        <el-button type="primary" size="mini"  @click="onSubmit"><i class="el-icon-check"></i><span>{{msg('确定')}}</span></el-button>
        <el-button  size="mini" v-on:click="$emit('closeDg')">{{msg('取消')}}</el-button>
    </div>
</el-form> 
</template>

<script>
export default {
  name: 'AccountEdit',
  data(){
    return{
        // account: {
        //     username: null,
        //     fullname: null,
        //     gender: null,
        //     disabled: null,
        //     roleId:null,
        //     password: null, 
        //     repassword: null
        // },
        roles:[],
    }
    
  }, 
  computed:{
      mode: {
          get(){
              return this.id==null;
          }
      }
  },
  props:{
      id:null,
      account:Object,
  },
  mounted: function(){
        var _this = this;
        if(this.roles.length == 0){
            this.$axios.post(this.$kit.api.roleList, {length: 999, start:0})
                .then(function(result){
                    if(result.status){
                        var data = result.data.data;
                        for(var i=0; i<data.length; i++){
                            _this.roles.push(data[i]);
                        }
                    }
                });
        }
        // this.initForm(this.id);
  },
  methods:{
    // initForm(accountId){
    //     var _this = this;
    //     if(accountId!=null){
    //         this.$axios.get(this.$kit.api.accountView+"?id="+accountId)
    //             .then(function(result){
    //                 _this.$message(result.msg);
    //                 if(result.status){
    //                     _this.account = result.data;
    //                 }
    //             });
    //     }else{
    //         this.account= {
    //             username: null,
    //             fullname: null,
    //             gender: null,
    //             disabled: null,
    //             roleId:null,
    //             password: null, 
    //             repassword: null
    //         }
    //     }
    // },
    onSubmit(){
        if(this.account.disabled){
            this.account.disabled=1;
        }else{
            this.account.disabled=0;
        }

        var url = null;
        if(this.id==null){
            url = this.$kit.api.accountCreate;
        }else{
            url = this.$kit.api.accountUpdate;
        }

        var form = {};

        for(var key in this.account){
            var value = this.account[key];
            if(value !== null && value !==""){
                form[key] = value;
            }
        }

        var _this = this;
        this.$axios.post(url, form)
            .then(function(result){
                if(result.status){
                    _this.$alert(result.msg || (_this.id==null? _this.msg("创建成功"): _this.msg('更新成功')), _this.msg('提示'), {
                        confirmButtonText: _this.msg('确定'),
                        callback: action => {
                            if(result.status){
                                _this.$emit("closeDg", true);
                            }
                        }
                    });
                }else{
                    _this.$message(result.msg || (_this.id==null? _this.msg('创建失败'):_this.msg('更新失败')));
                }
            });
    }
  }
}
</script>

<style class="scss" scoped>


</style>
